import { canUseDOM } from 'exenv'

export function clearLocalStorageItem(key) {
  if (!canUseDOM) return
  localStorage.removeItem(key)
}

export function getLocalStorageItem(key) {
  if (!canUseDOM) return

  const val = localStorage.getItem(key)
  return val && JSON.parse(val)
}

export function setLocalStorageItem(key, value) {
  if (!canUseDOM) return
  if (!value) return

  localStorage.setItem(key, JSON.stringify(value))
}
