import { InMemoryCache, gql, makeVar } from '@apollo/client'
import { getLocalStorageItem } from './util'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read: () => isLoggedInVar(),
        },
      },
    },
  },
})

export const isLoggedInVar = makeVar(!!getLocalStorageItem('apiToken'))

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`
export const resolvers = {}
