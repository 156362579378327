import { ApolloClient, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'

import { cache, resolvers, typeDefs } from './cache'
import { getLocalStorageItem } from './util'

const APOLLO_URL = `${process.env.GATSBY_API_URL}` || '/graphql'
const APOLLO_DEV_TOOLS = true

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorageItem('apiToken')

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  }
})

const httpLink = new HttpLink({
  // credentials: 'include',
  credentials: 'same-origin',
  fetch,
  uri: APOLLO_URL,
})

export const client = new ApolloClient({
  cache,
  connectToDevTools: APOLLO_DEV_TOOLS,
  link: httpLink,
  resolvers,
  typeDefs,
})
